import axios from "axios";

const BASE_URL = process.env.REACT_APP_AWS_API_GATEWAY_URL;

const axiosInstance = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

export const getAggregateOrdersByMonthLast3Years = async (token, accountId) => {
  try {
    const response = await axiosInstance.post(
      `/dashboards/aggregate-orders-by-month-last-3-years/`,
      { accountId: accountId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching the account:", error);
    throw error;
  }
};

export const getAggregateOpenOrdersAmount = async (token, accountId) => {
  try {
    const response = await axiosInstance.post(
      `/dashboards/aggregate-open-orders-amount/`,
      { accountId: accountId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching the account:", error);
    throw error;
  }
};

export const getAggregateCurrentMonthPaidOrdersAmount = async (token, accountId) => {
  try {
    const response = await axiosInstance.post(
      `/dashboards/aggregate-current-month-paid-orders-amount/`,
      { accountId: accountId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching the account:", error);
    throw error;
  }
};
