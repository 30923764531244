import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

import { getOrderLinesForAGivenDate } from "../../api/orderLinesAPI";

import Loader from "../shared/Loader";
import Maps from "../shared/Maps";
import EmptyState from "../shared/EmptyNoTask";

import { getLabel } from "../../utils/dataModelUtils";
import { getOrderDetailURL } from "../../utils/navigationUtils";
import { formatTime } from "../../utils/dateUtils";
import {
  prettifyName,
  formatFromAddressOrderLine,
  formatToAddressOrderLine,
  formatOrderLineType,
} from "../../utils/stringUtils";

export const Trips = (props) => {
  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();

  const [orderLines, setOrderLines] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        setOrderLines(null);
        const token = await getAccessTokenSilently();
        const data = await getOrderLinesForAGivenDate(
          token,
          props.selectedDate
        );
        setOrderLines(data);
      } catch (e) {
        setOrderLines([]);
        console.error(e);
      }
    })();
  }, [props.selectedDate]);

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };

  const formattedDate = (date) => {
    const options = { weekday: "long", day: "numeric", month: "long" };
    const dateParts = new Date(date)
      .toLocaleDateString("fr-FR", options)
      .split(" ");
    return dateParts.map(capitalizeFirstLetter).join(" ");
  };

  const [selectedOrderLine, setSelectedOrderLine] = useState(null);
  const [mapType, setMapType] = useState(null);

  return (
    <div className="slds-m-top_x-small">
      {!orderLines && <Loader />}
      {selectedOrderLine && (
        <Maps
          close={() => {
            setSelectedOrderLine(null);
            setMapType(null);
          }}
          orderline={selectedOrderLine}
          type={mapType}
        />
      )}
      {orderLines && (
        <div>
          <div
            className="slds-page-header"
            style={{
              borderBottomRightRadius: 0,
              borderBottomLeftRadius: 0,
            }}
          >
            <div className="slds-page-header__row">
              <div className="slds-page-header__col-title">
                <div className="slds-media">
                  <div className="slds-media__figure">
                    <span
                      className="slds-icon_container slds-icon-standard-work-order"
                      title="courses"
                    >
                      <svg
                        className="slds-icon slds-page-header__icon"
                        aria-hidden="true"
                      >
                        <use xlinkHref="/icons/standard-sprite/svg/symbols.svg#work_order"></use>
                      </svg>
                      <span className="slds-assistive-text">courses</span>
                    </span>
                  </div>
                  <div className="slds-media__body">
                    <div className="slds-page-header__name">
                      <div className="slds-page-header__name-title">
                        <h1>
                          <span
                            className="slds-page-header__title slds-truncate"
                            title="Rohde Corp - 80,000 Widgets"
                          >
                            {formattedDate(props.selectedDate)}
                          </span>
                        </h1>
                      </div>
                    </div>
                    <p className="slds-page-header__name-meta">
                      Planning du jour
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {orderLines.length === 0 && (
            <div className="slds-card slds-has-top-magnet slds-has-bottom-magnet">
              <div className="slds-p-around_large">
                <EmptyState heading={"Aucune course à venir"} />
              </div>
            </div>
          )}
          {orderLines.length > 0 && (
            <div className="slds-m-bottom_x-small">
              {orderLines.map((orderLine) => (
                <div
                  key={orderLine._id}
                  className="slds-card slds-has-top-magnet slds-has-bottom-magnet"
                >
                  <div className="slds-card__header slds-grid">
                    <header className="slds-media slds-media_center slds-has-flexi-truncate">
                      <div className="slds-media__figure">
                        <span
                          className="slds-icon_container slds-icon-standard-default"
                          title="clock"
                        >
                          <svg
                            className="slds-icon slds-icon_small"
                            aria-hidden="true"
                          >
                            <use xlinkHref="/icons/standard-sprite/svg/symbols.svg#today"></use>
                          </svg>
                          <span className="slds-assistive-text">clock</span>
                        </span>
                      </div>
                      <div className="slds-media__body">
                        <h2 className="slds-card__header-title">
                          <a
                            href="#order"
                            className="slds-card__header-link slds-truncate"
                            title={orderLine?.parentOrder?.orderReference}
                            onClick={() =>
                              navigate(getOrderDetailURL(orderLine?.orderId))
                            }
                          >
                            <span>{`${formatTime(
                              orderLine.startDate
                            )} - ${formatOrderLineType(orderLine.type)}`}</span>
                          </a>
                        </h2>
                      </div>
                      <div className="slds-no-flex">
                        <span className="slds-badge">
                          {getLabel(
                            "orderStatus",
                            orderLine?.parentOrder?.orderStatus
                          )}
                        </span>
                      </div>
                    </header>
                  </div>
                  <div className="slds-card__body slds-card__body_inner">
                    <div className="slds-tile__detail">
                      <dl className="slds-list_horizontal slds-wrap">
                        <dt className="slds-item_label slds-text-color_weak">
                          Client :
                        </dt>
                        <dd className="slds-item_detail">
                          {`${prettifyName(orderLine?.parentOrder?.account)}${
                            orderLine?.parentOrder?.account?.companyName
                              ? ` (${orderLine?.parentOrder?.account?.companyName})`
                              : ""
                          }`}
                        </dd>
                        <dt className="slds-item_label slds-text-color_weak">
                          Téléphone :
                        </dt>
                        <dd className="slds-item_detail">
                          <a
                            href={`tel:${orderLine?.parentOrder?.account?.phone}`}
                          >
                            {orderLine?.parentOrder?.account?.phone}
                          </a>
                        </dd>
                        <dt className="slds-item_label slds-text-color_weak">
                          Départ :
                        </dt>
                        <dd className="slds-item_detail">
                          {formatFromAddressOrderLine(orderLine)}
                        </dd>
                        {orderLine?.type === "transfert" && (
                          <dt className="slds-item_label slds-text-color_weak">
                            Destination :
                          </dt>
                        )}
                        {orderLine?.type === "transfert" && (
                          <dd className="slds-item_detail">
                            {formatToAddressOrderLine(orderLine)}
                          </dd>
                        )}
                        {orderLine?.type === "mise_a_disposition" && (
                          <dt className="slds-item_label slds-text-color_weak">
                            Durée :
                          </dt>
                        )}
                        {orderLine?.type === "mise_a_disposition" && (
                          <dd className="slds-item_detail">
                            {`${orderLine.numberOfHours}h`}
                          </dd>
                        )}
                      </dl>
                      <br></br>
                      <button
                        className="slds-button slds-button_neutral"
                        onClick={() => {
                          setSelectedOrderLine(orderLine);
                          setMapType("from");
                        }}
                      >
                        Départ
                      </button>
                      {orderLine?.type === "transfert" && (
                        <button
                          className="slds-button slds-button_neutral"
                          onClick={() => {
                            setSelectedOrderLine(orderLine);
                            setMapType("to");
                          }}
                        >
                          Destination
                        </button>
                      )}
                      {orderLine?.type === "transfert" && (
                        <button
                          className="slds-button slds-button_neutral"
                          onClick={() => {
                            setSelectedOrderLine(orderLine);
                            setMapType("direction");
                          }}
                        >
                          Itinéraire
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Trips;
