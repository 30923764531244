import React, { useState } from "react";

import RelatedOrders from "./RecordRelatedOrders.js";
import Detail from "../account/RecordDetail.js";
import AggregateOrdersByMonthLast3Years from "../dashboard/AggregateOrdersByMonthLast3Years";
import OpenOrdersAmount from "../dashboard/OpenOrdersAmount";

export const Tabs = (props) => {
  const [tabs, setTabs] = useState({
    orders: {
      label: "Commandes",
      isActive: true,
    },
    detail: {
      label: "Détail",
      isActive: false,
    },
    dashboards: {
      label: "Tableau de bord",
      isActive: false,
    },
  });

  const openTab = (tabName) => {
    const updatedTabs = { ...tabs };
    for (const tab in updatedTabs) {
      updatedTabs[tab].isActive = tab === tabName;
    }
    setTabs(updatedTabs);
  };

  return (
    <div className="slds-tabs_medium slds-tabs-mobile slds-var-p-around_small">
      <ul className="slds-tabs_default__nav" role="tablist">
        <li
          className={`slds-tabs_default__item slds-truncate ${
            tabs.orders.isActive ? "slds-is-active" : ""
          }`}
          title="Commandes"
          role="presentation"
        >
          <a
            className="slds-tabs_default__link"
            href="#orders"
            role="tab"
            tabIndex="-1"
            aria-selected={tabs.orders.isActive ? true : false}
            aria-controls="tab-default-2"
            onClick={() => openTab("orders")}
          >
            <span className="slds-tabs__left-icon">
              <span className="slds-icon_container slds-icon-standard-orders">
                <svg className="slds-icon slds-icon_small" aria-hidden="true">
                  <use xlinkHref="/icons/standard-sprite/svg/symbols.svg#orders"></use>
                </svg>
              </span>
            </span>
            {tabs.orders.label}
          </a>
        </li>
        <li
          className={`slds-tabs_default__item slds-hide_medium ${
            tabs.detail.isActive ? "slds-is-active" : ""
          }`}
          role="presentation"
        >
          <a
            className="slds-tabs_default__link"
            href="#detail"
            role="tab"
            tabIndex="0"
            aria-selected={tabs.detail.isActive ? true : false}
            aria-controls="tab-default-1"
            onClick={() => openTab("detail")}
          >
            <span className="slds-tabs__left-icon">
              <span className="slds-icon_container slds-icon-standard-account">
                <svg className="slds-icon slds-icon_small" aria-hidden="true">
                  <use xlinkHref="/icons/standard-sprite/svg/symbols.svg#record"></use>
                </svg>
              </span>
            </span>
            {tabs.detail.label}
          </a>
        </li>
        <li
          className={`slds-tabs_default__item slds-truncate ${
            tabs.dashboards.isActive ? "slds-is-active" : ""
          }`}
          title="Tableau de bord"
          role="presentation"
        >
          <a
            className="slds-tabs_default__link"
            href="#dashboards"
            role="tab"
            tabIndex="-1"
            aria-selected={tabs.dashboards.isActive ? true : false}
            aria-controls="tab-default-3"
            onClick={() => openTab("dashboards")}
          >
            <span className="slds-tabs__left-icon">
              <span className="slds-icon_container slds-icon-standard-apex">
                <svg className="slds-icon slds-icon_small" aria-hidden="true">
                  <use xlinkHref="/icons/standard-sprite/svg/symbols.svg#dashboard"></use>
                </svg>
              </span>
            </span>
            {tabs.dashboards.label}
          </a>
        </li>
      </ul>
      <div
        id="tab-default-2"
        className={`slds-tabs_default__content ${
          tabs.orders.isActive ? "slds-show" : "slds-hide"
        }`}
        role="tabpanel"
        aria-labelledby="tab-default-2__item"
      >
        <RelatedOrders account={props.account} />
      </div>
      <div
        id="tab-default-1"
        className={`slds-tabs_default__content slds-hide_medium ${
          tabs.detail.isActive ? "slds-show" : "slds-hide"
        }`}
        role="tabpanel"
        aria-labelledby="tab-default-1__item"
      >
        <Detail account={props.account} />
      </div>
      <div
        id="tab-default-2"
        className={`slds-tabs_default__content ${
          tabs.dashboards.isActive ? "slds-show" : "slds-hide"
        }`}
        role="tabpanel"
        aria-labelledby="tab-default-2__item"
      >
        {tabs.dashboards.isActive && (
          <div>
            <AggregateOrdersByMonthLast3Years account={props.account} />
            <OpenOrdersAmount account={props.account} />
          </div>
        )}
      </div>
    </div>
  );
};

export default Tabs;
