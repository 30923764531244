import React from "react";
import { useNavigate } from "react-router-dom";

import { formatDate } from "../../utils/dateUtils";

import { getOrderDetailURL } from "../../utils/navigationUtils";
import { getLabel } from "../../utils/dataModelUtils";

export const OrderResults = (props) => {
  const navigate = useNavigate();
  const openRecord = (recordId) => {
    navigate(getOrderDetailURL(recordId));
  };
  return (
    <div className="slds-m-bottom_x-small">
      <div
        className="slds-page-header"
        style={{ borderBottomRightRadius: 0, borderBottomLeftRadius: 0 }}
      >
        <div className="slds-page-header__row">
          <div className="slds-page-header__col-title">
            <div className="slds-media">
              <div className="slds-media__figure">
                <span
                  className="slds-icon_container slds-icon-standard-orders"
                  title="commande"
                >
                  <svg
                    className="slds-icon slds-page-header__icon"
                    aria-hidden="true"
                  >
                    <use xlinkHref="/icons/standard-sprite/svg/symbols.svg#orders"></use>
                  </svg>
                  <span className="slds-assistive-text">commande</span>
                </span>
              </div>
              <div className="slds-media__body">
                <div className="slds-page-header__name">
                  <div className="slds-page-header__name-title">
                    <h1>
                      <span
                        className="slds-page-header__title slds-truncate"
                        title="Rohde Corp - 80,000 Widgets"
                      >
                        Commandes
                      </span>
                    </h1>
                  </div>
                </div>
                <p className="slds-page-header__name-meta">
                  {`${props.orders.length} résultat(s)`}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <table
        className="slds-table slds-table_bordered slds-table_fixed-layout slds-max-medium-table_stacked"
        style={{
          borderRightWidth: 1,
          borderRightStyle: "solid",
          borderLeftWidth: 1,
          borderLeftStyle: "solid",
          borderColor: "#C9C9C9",
        }}
      >
        <thead>
          <tr className="slds-line-height_reset">
            <th className="slds-cell-wrap" scope="col">
              <div title="Nom du client">Référence</div>
            </th>
            <th className="slds-cell-wrap" scope="col">
              <div title="Montant">Date d'émission</div>
            </th>
            <th className="slds-cell-wrap" scope="col">
              <div title="Montant">Montant TTC</div>
            </th>
            <th className="slds-cell-wrap slds-show_small" scope="col">
              <div title="Statut">Statut</div>
            </th>
          </tr>
        </thead>
        <tbody>
          {props.orders.map((order) => (
            <tr className="slds-hint-parent datatable-row" key={order._id}>
              <th data-label="Nom" scope="row" className="slds-cell-wrap">
                <div className="slds-truncate datatable-value" title={order.orderReference}>
                  <a
                    href="#view"
                    tabIndex="-1"
                    onClick={() => openRecord(order._id)}
                  >
                    {order.orderReference}
                  </a>
                </div>
              </th>
              <td data-label="Date" className="slds-cell-wrap">
                <div className="slds-truncate datatable-value" title={formatDate(order.orderDate)}>
                  {formatDate(order.orderDate)}
                </div>
              </td>
              <td data-label="Montant TTC" className="slds-cell-wrap">
                <div className="slds-truncate datatable-value" title={order.totalAmountWithTax}>
                  {parseFloat(order.totalAmountWithTax).toFixed(2)}€
                </div>
              </td>
              <td
                data-label="Statut"
                className="slds-cell-wrap"
              >
                <div className="slds-truncate datatable-value" title={order.orderStatus}>
                  {getLabel("orderStatus", order.orderStatus)}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default OrderResults;
