import React from "react";
import { Helmet } from "react-helmet";

import AggregateOrdersByMonthLast3Years from "../components/dashboard/AggregateOrdersByMonthLast3Years";
import OpenOrdersAmount from "../components/dashboard/OpenOrdersAmount";
import CurrentMonthPaidOrdersAmount from "../components/dashboard/CurrentMonthPaidOrdersAmount";

export const Dashboards = () => {
  return (
    <div>
      <Helmet>
        <title>{`Kipzco - Tableau de bord`}</title>
      </Helmet>
      <div className="slds-page-header slds-m-bottom_x-small">
        <div className="slds-page-header__row">
          <div className="slds-page-header__col-title">
            <div className="slds-media">
              <div className="slds-media__figure">
                <span
                  className="slds-icon_container slds-icon-standard-apex"
                  title="home"
                >
                  <svg
                    className="slds-icon slds-page-header__icon"
                    aria-hidden="true"
                  >
                    <use xlinkHref="/icons/standard-sprite/svg/symbols.svg#dashboard"></use>
                  </svg>
                  <span className="slds-assistive-text">home</span>
                </span>
              </div>
              <div className="slds-media__body">
                <div className="slds-page-header__name">
                  <div className="slds-page-header__name-title">
                    <h1>
                      <span
                        className="slds-page-header__title slds-truncate"
                        title="Accueil"
                      >
                        Tableau de bord
                      </span>
                    </h1>
                  </div>
                </div>
                <p className="slds-page-header__name-meta">
                  {`Suivez l'évolution de votre activité`}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AggregateOrdersByMonthLast3Years />
      <CurrentMonthPaidOrdersAmount />
      <OpenOrdersAmount />
    </div>
  );
};

export default Dashboards;
