import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { getAggregateOrdersByMonthLast3Years } from "../../api/dashboardsAPI";
import { Loader } from "../shared/Loader";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const AggregateOrdersByMonthLast3Years = (props) => {
  const { getAccessTokenSilently } = useAuth0();
  const [loader, setLoader] = useState(true);
  const [datasets, setDatasets] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = await getAccessTokenSilently();
        const accountId = (props && props.account ? props.account._id : undefined);
        const fetchedData = await getAggregateOrdersByMonthLast3Years(token, accountId);
        
        // Apply the desired styles to the datasets
        if (fetchedData[0]) {
          fetchedData[0].borderColor = "#107cad";
          fetchedData[0].backgroundColor = "#107cad";
        }
        if (fetchedData[1]) {
          fetchedData[1].borderColor = "rgb(59, 167, 85, 0.5)";
          fetchedData[1].backgroundColor = "rgb(59, 167, 85, 0.5)";
        }
        if (fetchedData[2]) {
          fetchedData[2].borderColor = "rgb(6, 165, 154, 0.5)";
          fetchedData[2].backgroundColor = "rgb(6, 165, 154, 0.5)";
        }

        setDatasets(fetchedData);
      } catch (e) {
        console.error(e);
      } finally {
        setLoader(false);
      }
    };

    fetchData();
  }, []);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
      },
      title: {
        display: true,
        text: "Evolution du Chiffre d'Affaires",
      },
    },
  };

  const labels = [
    "Janvier",
    "Février",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juillet",
    "Août",
    "Septembre",
    "Octobre",
    "Novembre",
    "Décembre",
  ];

  const data = {
    labels,
    datasets: datasets,
  };

  return (
    <div>
      {loader ? (
        <Loader />
      ) : (
        <article className="slds-card">
          <div className="slds-card__body slds-card__body_inner">
            <Line options={options} data={data} />
          </div>
        </article>
      )}
    </div>
  );
};

export default AggregateOrdersByMonthLast3Years;
