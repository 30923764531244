import React, { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";

import { updateOrderLine } from "../../api/ordersAPI";

import Autocomplete from "../shared/Autocomplete";
import { Datepicker } from "../shared/Datepicker";
import { Loader } from "../shared/Loader";

import { extractTimeFromDate } from "../../utils/dateUtils";
import {
  formatFromAddressOrderLine,
  formatToAddressOrderLine,
} from "../../utils/stringUtils";
import {
  getTransfertOrderLineState,
  validateTransfert,
} from "../../utils/orderLinesUtils";

export const EditOrderLineTransfert = (props) => {
  const { getAccessTokenSilently } = useAuth0();

  const [orderLineState, setOrderLineState] = useState(() => {
    let initialState = getTransfertOrderLineState(props.orderLine);
    delete initialState.orderId;
    delete initialState.type;
    return initialState;
  });

  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setOrderLineState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const setOrderLineStartTime = (e) => {
    // Get the time value from the input
    const timeValue = e.target.value;
    // Create a new date object from the current startDate state
    let newStartDate = new Date(orderLineState.startDate);

    if (timeValue !== "") {
      const [hours, minutes] = timeValue.split(":");

      newStartDate.setHours(hours, minutes);

      // Update the state
      setOrderLineState((prevState) => ({
        ...prevState,
        startDate: newStartDate,
      }));
    } else {
      // Ensure that hours and minutes are always two digits
      const formattedHours = String(newStartDate.getHours()).padStart(2, "0");
      const formattedMinutes = String(newStartDate.getMinutes()).padStart(
        2,
        "0"
      );
      e.target.value = `${formattedHours}:${formattedMinutes}`;
    }
  };

  const setOrderLineStartDate = (date) => {
    // Get the current time from the state's startDate
    const currentStartDate = new Date(orderLineState.startDate);
    const hours = currentStartDate.getHours();
    const minutes = currentStartDate.getMinutes();

    // Set the time to the date object
    date.setHours(hours, minutes);

    // Update the state
    setOrderLineState((prevState) => ({
      ...prevState,
      startDate: date,
    }));
  };

  const setFromAddress = (autocomplete) => {
    let mapping = {};
    let place = autocomplete.getPlace();
    for (let i = 0; i < place.address_components.length; i++) {
      let addressType = place.address_components[i].types[0];
      mapping[addressType] = place.address_components[i].long_name;
    }
    setOrderLineState((prevState) => ({
      ...prevState,
      fromAddressName: place.name,
      fromAddressStreetNumber: mapping.street_number,
      fromAddressRoute: mapping.route,
      fromAddressCity: mapping.locality,
      fromAddressZipcode: mapping.postal_code,
      fromAddressCountry: mapping.country,
      fromAddressLat: place.geometry.location.lat(),
      fromAddressLng: place.geometry.location.lng(),
    }));
  };

  const setDestinationAddress = (autocomplete) => {
    let mapping = {};
    let place = autocomplete.getPlace();
    for (let i = 0; i < place.address_components.length; i++) {
      let addressType = place.address_components[i].types[0];
      mapping[addressType] = place.address_components[i].long_name;
    }
    setOrderLineState((prevState) => ({
      ...prevState,
      toAddressName: place.name,
      toAddressStreetNumber: mapping.street_number,
      toAddressRoute: mapping.route,
      toAddressCity: mapping.locality,
      toAddressZipcode: mapping.postal_code,
      toAddressCountry: mapping.country,
      toAddressLat: place.geometry.location.lat(),
      toAddressLng: place.geometry.location.lng(),
    }));
  };

  const handleSave = async () => {
    if (!validateTransfert(orderLineState)) {
      alert("Veuillez remplir tous les champs obligatoires !");
      return;
    }
    if (isButtonDisabled) {
      return;
    } else {
      setIsButtonDisabled(true);
    }
    try {
      const token = await getAccessTokenSilently();
      await updateOrderLine(
        props.orderLine?.orderId,
        props.orderLine?._id,
        orderLineState,
        token
      );
      props.refresh && props.refresh();
      props.onClose && props.onClose();
    } catch (e) {
      console.error(e);
    } finally {
      setIsButtonDisabled(false);
    }
  };

  const handleClose = () => {
    props.onClose && props.onClose();
  };

  const openMapsDirection = (orderline) => {
    window.open(
      "https://www.google.com/maps/dir/" +
        formatFromAddressOrderLine(orderline) +
        "/" +
        formatToAddressOrderLine(orderline)
    );
  };

  return (
    <div>
      {isButtonDisabled && <Loader />}
      <section
        role="dialog"
        tabIndex="-1"
        aria-modal="true"
        aria-labelledby="modal-heading-01"
        className="slds-modal slds-fade-in-open slds-modal_x-small"
      >
        <div className="slds-modal__container">
          <button
            className="slds-button slds-button_icon slds-modal__close slds-button_icon-inverse"
            onClick={handleClose}
          >
            <svg
              className="slds-button__icon slds-button__icon_large"
              aria-hidden="true"
            >
              <use xlinkHref="/icons/utility-sprite/svg/symbols.svg#close"></use>
            </svg>
            <span className="slds-assistive-text">
              Annuler la création et fermer la fenêtre.
            </span>
          </button>
          <div className="slds-modal__header">
            <h1
              id="modal-heading-01"
              className="slds-modal__title slds-hyphenate"
            >
              Modifier le Transfert
            </h1>
          </div>
          <div
            className="slds-modal__content slds-var-p-around_large"
            id="modal-content-id-1"
          >
            <section>
              <div>
                <Datepicker
                  label="Date et heure de la prestation*"
                  onSet={setOrderLineStartDate}
                  selectedValue={props.orderLine?.startDate}
                />
                <input
                  type="time"
                  id="startTime"
                  name="time"
                  className="slds-input slds-m-top_xx-small"
                  required={true}
                  defaultValue={extractTimeFromDate(props.orderLine?.startDate)}
                  onChange={(e) => setOrderLineStartTime(e)}
                />
              </div>
            </section>
            <section>
              <Autocomplete
                setAddress={setFromAddress}
                name="address_depart"
                label="Adresse de Départ*"
                placeholder="ex: Aéroport Paris CDG"
                selectedValue={formatFromAddressOrderLine(props.orderLine)}
              />
            </section>
            <section>
              <Autocomplete
                setAddress={setDestinationAddress}
                name="address_destination"
                label="Adresse de Destination*"
                placeholder="ex: Aéroport Marseille St Charles"
                selectedValue={formatToAddressOrderLine(props.orderLine)}
              />
            </section>
            <section>
              <div className="slds-form-element">
                <label
                  className="slds-form-element__label"
                  htmlFor="text-id-totalAmountWithTax"
                >
                  Tarif TTC*
                </label>
                <div className="slds-form-element__control">
                  <input
                    type="number"
                    min="1"
                    step="1"
                    pattern="[0-9]*"
                    id="text-id-totalAmountWithTax"
                    placeholder="ex: 250€"
                    name="totalAmountWithTax"
                    className="slds-input"
                    required={true}
                    value={orderLineState.totalAmountWithTax || ""}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
            </section>
            <section>
              <div>
                <div className="slds-form-element">
                  <label
                    className="slds-form-element__label"
                    htmlFor="textarea-id-description"
                  >
                    Information complémentaire
                  </label>
                  <div className="slds-form-element__control">
                    <textarea
                      id="textarea-id-description"
                      className="slds-textarea"
                      rows="5"
                      cols="50"
                      placeholder="ex: type de véhicule, nombre de passagers, tarif de nuit, heure supplémentaire, etc..."
                      maxLength="255"
                      name="description"
                      value={orderLineState.description || ""}
                      onChange={handleInputChange}
                    ></textarea>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <div className="slds-modal__footer slds-modal__footer_directional">
            <button
              className="slds-button slds-button_neutral"
              aria-label="Maps"
              onClick={() => openMapsDirection(orderLineState)}
              disabled={isButtonDisabled}
            >
              <svg
                className="slds-button__icon slds-button__icon_left"
                aria-hidden="true"
              >
                <use xlinkHref="/icons/utility-sprite/svg/symbols.svg#checkin"></use>
              </svg>
              Maps
            </button>
            <button
              className="slds-button slds-button_neutral slds-m-bottom_xxx-small"
              aria-label="Cancel and close"
              onClick={handleClose}
              disabled={isButtonDisabled}
            >
              <svg
                className="slds-button__icon slds-button__icon_left"
                aria-hidden="true"
              >
                <use xlinkHref="/icons/utility-sprite/svg/symbols.svg#back"></use>
              </svg>
              Annuler
            </button>
            <button
              className="slds-button slds-button_brand"
              onClick={handleSave}
              disabled={isButtonDisabled}
            >
              <svg
                className="slds-button__icon slds-button__icon_left"
                aria-hidden="true"
              >
                <use xlinkHref="/icons/utility-sprite/svg/symbols.svg#save"></use>
              </svg>
              Enregistrer
            </button>
          </div>
        </div>
      </section>
      <div
        className="slds-backdrop slds-backdrop_open"
        role="presentation"
      ></div>
    </div>
  );
};
export default EditOrderLineTransfert;
