import React from "react";
import { useNavigate } from "react-router-dom";

import { formatDate } from "../../utils/dateUtils";
import { prettifyName } from "../../utils/stringUtils";
import {
  getAccountDetailURL,
  getOrderDetailURL,
} from "../../utils/navigationUtils";
import { getLabel } from "../../utils/dataModelUtils";

export const DataTable = (props) => {
  const navigate = useNavigate();
  const openRecord = (recordId) => {
    navigate(getOrderDetailURL(recordId));
  };
  const openAccountRecord = (recordId) => {
    navigate(getAccountDetailURL(recordId));
  };

  return (
    <table
      className="slds-table slds-table_bordered slds-table_fixed-layout slds-max-medium-table_stacked"
      style={{
        borderRightWidth: 1,
        borderRightStyle: "solid",
        borderLeftWidth: 1,
        borderLeftStyle: "solid",
        borderColor: "#C9C9C9",
      }}
    >
      <thead>
        <tr className="slds-line-height_reset">
          <th className="slds-cell-wrap" scope="col">
            <div title="Référence">Référence</div>
          </th>
          <th className="slds-cell-wrap" scope="col">
            <div title="Client">Client</div>
          </th>
          <th className="slds-cell-wrap slds-show_small" scope="col">
            <div title="Entreprise">Entreprise</div>
          </th>
          <th className="slds-cell-wrap" scope="col">
            <div title="Date d'émission">Date d'émission</div>
          </th>
          <th className="slds-cell-wrap" scope="col">
            <div title="Montant TTC">Montant TTC</div>
          </th>
          <th className="slds-cell-wrap slds-show_small" scope="col">
            <div title="Statut">Statut</div>
          </th>
        </tr>
      </thead>
      <tbody>
        {props.orders.map((order) => (
          <tr className="slds-hint-parent datatable-row" key={order._id}>
            <th data-label="Référence" scope="row" className="slds-cell-wrap">
              <div className="slds-truncate datatable-value" title={order.orderReference}>
                <a
                  href="#view"
                  tabIndex="-1"
                  onClick={() => openRecord(order._id)}
                >
                  {order.orderReference}
                </a>
              </div>
            </th>
            <td data-label="Client" className="slds-cell-wrap">
              <div className="slds-truncate datatable-value" title={prettifyName(order.parentAccount)}>
                <a
                  href="#view"
                  tabIndex="-1"
                  onClick={() => openAccountRecord(order.parentAccount._id)}
                >
                  {prettifyName(order.parentAccount)}
                </a>
              </div>
            </td>
            <td data-label="Entreprise" className="slds-cell-wrap slds-show_small">
              <div className="slds-truncate datatable-value" title={order.parentAccount.companyName}>
                {order.parentAccount.companyName}
              </div>
            </td>
            <td data-label="Date" className="slds-cell-wrap">
              <div className="slds-truncate datatable-value" title={formatDate(order.orderDate)}>
                {formatDate(order.orderDate)}
              </div>
            </td>
            <td data-label="Montant TTC" className="slds-cell-wrap">
              <div className="slds-truncate datatable-value" title={order.totalAmountWithTax}>
                {parseFloat(order.totalAmountWithTax).toFixed(2)}€
              </div>
            </td>
            <td data-label="Statut" className="slds-cell-wrap">
              <div className="slds-truncate datatable-value" title={order.orderStatus}>
                {getLabel("orderStatus", order.orderStatus)}
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
export default DataTable;
