import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

import {
  getHomeURL,
  getAccountListURL,
  getOrderListURL,
  getDashboardsURL,
  getAgendaURL,
} from "../../utils/navigationUtils";

export const HorizontalNavigation = (props) => {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <div className="slds-global-header" style={{ padding: "0.7rem 0" }}>
      <div className="slds-context-bar">
        <div className="slds-context-bar__primary slds-show_small">
          <div className="slds-context-bar__item slds-context-bar__dropdown-trigger slds-dropdown-trigger slds-dropdown-trigger_click slds-no-hover">
            <div className="slds-context-bar__icon-action">
              <button
                className="slds-button slds-icon-waffle_container slds-context-bar__button"
                title="Description of the icon when needed"
              >
                <span className="slds-icon-waffle">
                  <span className="slds-r1"></span>
                  <span className="slds-r2"></span>
                  <span className="slds-r3"></span>
                  <span className="slds-r4"></span>
                  <span className="slds-r5"></span>
                  <span className="slds-r6"></span>
                  <span className="slds-r7"></span>
                  <span className="slds-r8"></span>
                  <span className="slds-r9"></span>
                </span>
                <span className="slds-assistive-text">Open App Launcher</span>
              </button>
            </div>
            <span className="slds-context-bar__label-action slds-context-bar__app-name">
              <span className="slds-truncate" title="App Name">
                GESTION
              </span>
            </span>
          </div>
        </div>
        <nav className="slds-context-bar__secondary" role="navigation">
          <ul className="slds-grid">
            <li
              className={`slds-context-bar__item ${
                location.pathname === "/" ? "slds-is-active" : ""
              }`}
            >
              <a
                href="#home"
                className="slds-context-bar__label-action"
                title="Accueil"
                onClick={() => navigate(getHomeURL())}
              >
                <span className="slds-truncate" title="Accueil">
                  Accueil
                </span>
              </a>
            </li>
            <li
              className={`slds-context-bar__item ${
                location.pathname.startsWith("/agenda")
                  ? "slds-is-active"
                  : ""
              }`}
            >
              <a
                href="#clients"
                className="slds-context-bar__label-action"
                title="Clients"
                onClick={() => navigate(getAgendaURL())}
              >
                <span className="slds-truncate" title="Clients">
                  Agenda
                </span>
              </a>
            </li>
            <li
              className={`slds-context-bar__item ${
                location.pathname.startsWith("/accounts")
                  ? "slds-is-active"
                  : ""
              }`}
            >
              <a
                href="#clients"
                className="slds-context-bar__label-action"
                title="Clients"
                onClick={() => navigate(getAccountListURL())}
              >
                <span className="slds-truncate" title="Clients">
                  Clients
                </span>
              </a>
            </li>
            <li
              className={`slds-context-bar__item ${
                location.pathname.startsWith("/orders") ? "slds-is-active" : ""
              }`}
            >
              <a
                href="#orders"
                className="slds-context-bar__label-action"
                title="Commandes"
                onClick={() => navigate(getOrderListURL())}
              >
                <span className="slds-truncate" title="Commandes">
                  Commandes
                </span>
              </a>
            </li>
            <li
              className={`slds-context-bar__item ${
                location.pathname.startsWith("/dashboards") ? "slds-is-active" : ""
              }`}
            >
              <a
                href="#orders"
                className="slds-context-bar__label-action"
                title="Tableau de bord"
                onClick={() => navigate(getDashboardsURL())}
              >
                <span className="slds-truncate" title="Commandes">
                  Tableau de bord
                </span>
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default HorizontalNavigation;
