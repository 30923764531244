import React, { useState } from "react";

export const Datepicker = (props) => {
  const today = new Date().toISOString().split("T")[0];
  const [date, setDate] = useState(today);

  const handleDateChange = (event) => {
    const newDate = event.target.value;
    setDate(newDate);
    props.onDateChange(newDate);
  };

  return (
    <article className="slds-card">
      <div className="slds-card__header slds-grid">
        <header className="slds-media slds-media_center slds-has-flexi-truncate">
          <div className="slds-media__figure">
            <span
              className="slds-icon_container slds-icon-standard-default"
              title="account"
            >
              <svg className="slds-icon slds-icon_small" aria-hidden="true">
                <use xlinkHref="/icons/standard-sprite/svg/symbols.svg#choice"></use>
              </svg>
              <span className="slds-assistive-text">Sélectionner la date</span>
            </span>
          </div>
          <div className="slds-media__body">
            <input
              type="date"
              value={date}
              onChange={handleDateChange}
              className="slds-input"
              required={true}
              id="date-input-id-default"
            />
          </div>
        </header>
      </div>
    </article>
  );
};

export default Datepicker;
