import React, { useState } from "react";

import Edit from "../order/EditModal.js";
import Delete from "../order/DeleteModal.js";
import Finalize from "./FinalizeModal.js";
import Paid from "./PaidModal.js";
import Uncollectible from "./UncollectibleModal.js";
import Send from "../order/SendModal.js";
import Void from "../order/VoidModal.js";
import Clone from "../order/CloneModal.js";
import Invoice from "./InvoiceModal.js";
import Quote from "../order/QuoteModal.js";

export const RecordPageHeaderActions = (props) => {
  const [isEditVisible, setIsEditVisible] = useState(false);
  const [isDeleteVisible, setIsDeleteVisible] = useState(false);
  const [isFinalizeVisible, setIsFinalizeVisible] = useState(false);
  const [isSendInvoiceVisible, setIsSendInvoiceVisible] = useState(false);
  const [isPaidInvoiceVisible, setIsPaidInvoiceVisible] = useState(false);
  const [isUncollectibleInvoiceVisible, setIsUncollectibleInvoiceVisible] =
    useState(false);
  const [isVoidInvoiceVisible, setIsVoidInvoiceVisible] = useState(false);
  const [isCloneInvoiceVisible, setIsCloneInvoiceVisible] = useState(false);
  const [isInvoiceVisible, setIsInvoiceVisible] = useState(false);
  const [isQuoteVisible, setIsQuoteVisible] = useState(false);
  const [isMoreActionsVisible, setIsMoreActionsVisible] = useState(false);
  return (
    <div className="slds-page-header__col-actions">
      <div className="slds-page-header__controls">
        <div className="slds-page-header__control">
          <ul className="slds-button-group-list">
            {!props.order.stripeInvoiceId && (
              <li>
                <button
                  className="slds-button slds-button_neutral"
                  onClick={() => setIsFinalizeVisible(true)}
                  disabled={props.order.totalAmountWithTax < 1}
                >
                  <svg
                    className="slds-button__icon slds-button__icon_left"
                    aria-hidden="true"
                  >
                    <use xlinkHref="/icons/utility-sprite/svg/symbols.svg#lock"></use>
                  </svg>
                  Finaliser
                </button>
              </li>
            )}
            {props.order.stripeInvoiceId &&
              props.order.orderStatus === "open" && (
                <li>
                  <button
                    className="slds-button slds-button_neutral"
                    onClick={() => setIsSendInvoiceVisible(true)}
                  >
                    <svg
                      className="slds-button__icon slds-button__icon_left"
                      aria-hidden="true"
                    >
                      <use xlinkHref="/icons/utility-sprite/svg/symbols.svg#email"></use>
                    </svg>
                    Envoyer
                  </button>
                </li>
              )}
            {props.order.stripeInvoiceId &&
              props.order.orderStatus !== "draft" &&
              props.order.orderStatus !== "open" && (
                <li>
                  <button
                    className="slds-button slds-button_neutral"
                    onClick={() => setIsCloneInvoiceVisible(true)}
                  >
                    <svg
                      className="slds-button__icon slds-button__icon_left"
                      aria-hidden="true"
                    >
                      <use xlinkHref="/icons/utility-sprite/svg/symbols.svg#copy"></use>
                    </svg>
                    Dupliquer
                  </button>
                </li>
              )}
            <li>
              <button
                className="slds-button slds-button_neutral slds-show_large"
                disabled={props.order.totalAmountWithTax < 1}
                onClick={() => setIsQuoteVisible(true)}
              >
                <svg
                  className="slds-button__icon slds-button__icon_left"
                  aria-hidden="true"
                >
                  <use xlinkHref="/icons/utility-sprite/svg/symbols.svg#quote"></use>
                </svg>
                Devis
              </button>
            </li>
            {props.order.stripeInvoiceId && (
              <li>
                <button
                  className="slds-button slds-button_neutral slds-show_large"
                  onClick={() => setIsInvoiceVisible(true)}
                >
                  <svg
                    className="slds-button__icon slds-button__icon_left"
                    aria-hidden="true"
                  >
                    <use xlinkHref="/icons/utility-sprite/svg/symbols.svg#contract_doc"></use>
                  </svg>
                  Facture
                </button>
              </li>
            )}
            {props.order.stripeInvoiceId &&
              props.order.orderStatus === "open" && (
                <li>
                  <button
                    className="slds-button slds-button_neutral slds-show_large"
                    onClick={() => setIsPaidInvoiceVisible(true)}
                  >
                    <svg
                      className="slds-button__icon slds-button__icon_left"
                      aria-hidden="true"
                    >
                      <use xlinkHref="/icons/utility-sprite/svg/symbols.svg#success"></use>
                    </svg>
                    Payée
                  </button>
                </li>
              )}
            {props.order.stripeInvoiceId &&
              props.order.orderStatus === "open" && (
                <li>
                  <button
                    className="slds-button slds-button_neutral slds-show_large"
                    onClick={() => setIsUncollectibleInvoiceVisible(true)}
                  >
                    <svg
                      className="slds-button__icon slds-button__icon_left"
                      aria-hidden="true"
                    >
                      <use xlinkHref="/icons/utility-sprite/svg/symbols.svg#thunder"></use>
                    </svg>
                    Impayée
                  </button>
                </li>
              )}
            {props.order.stripeInvoiceId &&
              props.order.orderStatus === "open" && (
                <li>
                  <button
                    className="slds-button slds-button_neutral slds-show_large"
                    onClick={() => setIsVoidInvoiceVisible(true)}
                  >
                    <svg
                      className="slds-button__icon slds-button__icon_left"
                      aria-hidden="true"
                    >
                      <use xlinkHref="/icons/utility-sprite/svg/symbols.svg#ban"></use>
                    </svg>
                    Annuler
                  </button>
                </li>
              )}
            {!props.order.stripeInvoiceId && (
              <li>
                <button
                  className="slds-button slds-button_neutral slds-show_large"
                  onClick={() => setIsEditVisible(true)}
                >
                  <svg
                    className="slds-button__icon slds-button__icon_left"
                    aria-hidden="true"
                  >
                    <use xlinkHref="/icons/utility-sprite/svg/symbols.svg#edit"></use>
                  </svg>
                  Modifier
                </button>
              </li>
            )}
            {!props.order.stripeInvoiceId && (
              <li>
                <button
                  className="slds-button slds-button_neutral slds-show_large"
                  onClick={() => setIsDeleteVisible(true)}
                >
                  <svg
                    className="slds-button__icon slds-button__icon_left"
                    aria-hidden="true"
                  >
                    <use xlinkHref="/icons/utility-sprite/svg/symbols.svg#delete"></use>
                  </svg>
                  Supprimer
                </button>
              </li>
            )}
            <li className="slds-hide_large">
              <div
                className={`slds-dropdown-trigger slds-dropdown-trigger_click ${
                  isMoreActionsVisible ? "slds-is-open" : ""
                }`}
                onClick={() => setIsMoreActionsVisible(!isMoreActionsVisible)}
              >
                <button
                  className="slds-button slds-button_icon slds-button_icon-border-filled"
                  aria-haspopup="true"
                  title="More Actions"
                >
                  <svg className="slds-button__icon" aria-hidden="true">
                    <use xlinkHref="/icons/utility-sprite/svg/symbols.svg#down"></use>
                  </svg>
                  <span className="slds-assistive-text">More Actions</span>
                </button>
                <div
                  className="slds-dropdown slds-dropdown_right slds-dropdown_actions"
                  style={{ zIndex: 999 }}
                >
                  <ul
                    className="slds-dropdown__list"
                    role="menu"
                    aria-label="Show More"
                  >
                    <li className="slds-dropdown__item" role="presentation">
                      <a href="#quote" role="menuitem" tabIndex="2">
                        <span
                          className="slds-truncate"
                          title="Devis"
                          disabled={props.order.totalAmountWithTax < 1}
                          onClick={() => setIsQuoteVisible(true)}
                        >
                          <svg
                            className="slds-button__icon slds-button__icon_left"
                            aria-hidden="true"
                          >
                            <use xlinkHref="/icons/utility-sprite/svg/symbols.svg#quote"></use>
                          </svg>
                          Devis
                        </span>
                      </a>
                    </li>
                    {props.order.stripeInvoiceId &&
                      props.order.orderStatus !== "draft" && (
                        <li className="slds-dropdown__item" role="presentation">
                          <a href="#invoice" role="menuitem" tabIndex="2">
                            <span
                              className="slds-truncate"
                              title="Facture"
                              onClick={() => setIsInvoiceVisible(true)}
                            >
                              <svg
                                className="slds-button__icon slds-button__icon_left"
                                aria-hidden="true"
                              >
                                <use xlinkHref="/icons/utility-sprite/svg/symbols.svg#contract_doc"></use>
                              </svg>
                              Facture
                            </span>
                          </a>
                        </li>
                      )}
                    {props.order.stripeInvoiceId &&
                      props.order.orderStatus === "open" && (
                        <li className="slds-dropdown__item" role="presentation">
                          <a href="#paid" role="menuitem" tabIndex="2">
                            <span
                              className="slds-truncate"
                              title="Payée"
                              onClick={() => setIsPaidInvoiceVisible(true)}
                            >
                              <svg
                                className="slds-button__icon slds-button__icon_left"
                                aria-hidden="true"
                              >
                                <use xlinkHref="/icons/utility-sprite/svg/symbols.svg#success"></use>
                              </svg>
                              Payée
                            </span>
                          </a>
                        </li>
                      )}
                    {props.order.stripeInvoiceId &&
                      props.order.orderStatus === "open" && (
                        <li className="slds-dropdown__item" role="presentation">
                          <a href="#unpaid" role="menuitem" tabIndex="2">
                            <span
                              className="slds-truncate"
                              title="Impayée"
                              onClick={() =>
                                setIsUncollectibleInvoiceVisible(true)
                              }
                            >
                              <svg
                                className="slds-button__icon slds-button__icon_left"
                                aria-hidden="true"
                              >
                                <use xlinkHref="/icons/utility-sprite/svg/symbols.svg#thunder"></use>
                              </svg>
                              Impayée
                            </span>
                          </a>
                        </li>
                      )}
                    {props.order.stripeInvoiceId &&
                      props.order.orderStatus === "open" && (
                        <li className="slds-dropdown__item" role="presentation">
                          <a href="#cancel" role="menuitem" tabIndex="2">
                            <span
                              className="slds-truncate"
                              title="Annuler"
                              onClick={() => setIsVoidInvoiceVisible(true)}
                            >
                              <svg
                                className="slds-button__icon slds-button__icon_left"
                                aria-hidden="true"
                              >
                                <use xlinkHref="/icons/utility-sprite/svg/symbols.svg#ban"></use>
                              </svg>
                              Annuler
                            </span>
                          </a>
                        </li>
                      )}
                    {!props.order.stripeInvoiceId && (
                      <li className="slds-dropdown__item" role="presentation">
                        <a href="#edit" role="menuitem" tabIndex="2">
                          <span
                            className="slds-truncate"
                            title="Modifier"
                            onClick={() => setIsEditVisible(true)}
                          >
                            <svg
                              className="slds-button__icon slds-button__icon_left"
                              aria-hidden="true"
                            >
                              <use xlinkHref="/icons/utility-sprite/svg/symbols.svg#edit"></use>
                            </svg>
                            Modifier
                          </span>
                        </a>
                      </li>
                    )}
                    {!props.order.stripeInvoiceId && (
                      <li className="slds-dropdown__item" role="presentation">
                        <a href="#delete" role="menuitem" tabIndex="2">
                          <span
                            className="slds-truncate"
                            title="Supprimer"
                            onClick={() => setIsDeleteVisible(true)}
                          >
                            <svg
                              className="slds-button__icon slds-button__icon_left"
                              aria-hidden="true"
                            >
                              <use xlinkHref="/icons/utility-sprite/svg/symbols.svg#delete"></use>
                            </svg>
                            Supprimer
                          </span>
                        </a>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      {isEditVisible && (
        <Edit
          order={props.order}
          onClose={() => setIsEditVisible(false)}
          refetchData={props.refetchData}
        />
      )}
      {isDeleteVisible && (
        <Delete order={props.order} onClose={() => setIsDeleteVisible(false)} />
      )}
      {isFinalizeVisible && (
        <Finalize
          order={props.order}
          onClose={() => setIsFinalizeVisible(false)}
          refetchData={props.refetchData}
        />
      )}
      {isSendInvoiceVisible && (
        <Send
          order={props.order}
          onClose={() => setIsSendInvoiceVisible(false)}
          refetchData={props.refetchData}
        />
      )}
      {isPaidInvoiceVisible && (
        <Paid
          order={props.order}
          onClose={() => setIsPaidInvoiceVisible(false)}
          refetchData={props.refetchData}
        />
      )}
      {isUncollectibleInvoiceVisible && (
        <Uncollectible
          order={props.order}
          onClose={() => setIsUncollectibleInvoiceVisible(false)}
          refetchData={props.refetchData}
        />
      )}
      {isVoidInvoiceVisible && (
        <Void
          order={props.order}
          onClose={() => setIsVoidInvoiceVisible(false)}
          refetchData={props.refetchData}
        />
      )}
      {isCloneInvoiceVisible && (
        <Clone
          order={props.order}
          onClose={() => setIsCloneInvoiceVisible(false)}
          refetchData={props.refetchData}
        />
      )}
      {isInvoiceVisible && (
        <Invoice
          order={props.order}
          onClose={() => setIsInvoiceVisible(false)}
          refetchData={props.refetchData}
        />
      )}
      {isQuoteVisible && (
        <Quote
          order={props.order}
          onClose={() => setIsQuoteVisible(false)}
          refetchData={props.refetchData}
        />
      )}
    </div>
  );
};

export default RecordPageHeaderActions;
