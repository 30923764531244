import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { getQuote } from "../../api/stripeQuotesAPI";
import { Loader } from "../shared/Loader";

export const QuoteModal = (props) => {
  const { getAccessTokenSilently } = useAuth0();
  const [loader, setLoader] = useState(true);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [quoteUrl, setQuoteUrl] = useState(null);

  useEffect(() => {
    const fetchQuoteUrl = async () => {
      try {
        const token = await getAccessTokenSilently();
        const quote = await getQuote(token, props.order._id);
        if(quote && quote.quote_pdf){
          setQuoteUrl(quote.quote_pdf);
          setIsButtonDisabled(false);
        }
      } catch (e) {
        console.error(e);
      } finally {
        setLoader(false);
      }
    };

    fetchQuoteUrl();
  }, [getAccessTokenSilently, props.order.stripeQuoteId]);

  const handleDownload = () => {
    if (quoteUrl) {
      window.open(quoteUrl, "_blank");
      props.onClose && props.onClose();
    }
  };

  const handleClose = () => {
    props.onClose && props.onClose();
  };

  return (
    <div>
      {loader && <Loader />}
      <section
        role="dialog"
        tabIndex="-1"
        aria-modal="true"
        aria-labelledby="modal-heading-01"
        className="slds-modal slds-fade-in-open slds-modal_small"
      >
        <div className="slds-modal__container">
          <button
            className="slds-button slds-button_icon slds-modal__close slds-button_icon-inverse"
            onClick={handleClose}
          >
            <svg
              className="slds-button__icon slds-button__icon_large"
              aria-hidden="true"
            >
              <use xlinkHref="/icons/utility-sprite/svg/symbols.svg#close"></use>
            </svg>
            <span className="slds-assistive-text">
              Annuler et fermer la fenêtre.
            </span>
          </button>
          <div className="slds-modal__header">
            <h1
              id="modal-heading-01"
              className="slds-modal__title slds-hyphenate"
            >
              Devis
            </h1>
          </div>
          <div
            className="slds-modal__content slds-var-p-around_large"
            id="modal-content-id-1"
          >
            <div className="slds-align_absolute-center">
              <p>
                {!quoteUrl &&
                  "Veuillez patienter, votre devis est en cours de préparation..."}
                {quoteUrl &&
                  "Votre devis est prêt, vous pouvez à présent le télécharger."}
              </p>
            </div>
          </div>
          <div className="slds-modal__footer">
            <button
              className="slds-button slds-button_neutral"
              aria-label="Cancel and close"
              onClick={handleClose}
            >
              <svg
                className="slds-button__icon slds-button__icon_left"
                aria-hidden="true"
              >
                <use xlinkHref="/icons/utility-sprite/svg/symbols.svg#back"></use>
              </svg>
              Annuler
            </button>
            <button
              className="slds-button slds-button_brand"
              onClick={handleDownload}
              disabled={isButtonDisabled}
            >
              <svg
                className="slds-button__icon slds-button__icon_left"
                aria-hidden="true"
              >
                <use xlinkHref="/icons/utility-sprite/svg/symbols.svg#download"></use>
              </svg>
              Télécharger
            </button>
          </div>
        </div>
      </section>
      <div
        className="slds-backdrop slds-backdrop_open"
        role="presentation"
      ></div>
    </div>
  );
};
export default QuoteModal;
