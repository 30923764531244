import axios from "axios";

const BASE_URL = process.env.REACT_APP_AWS_API_GATEWAY_URL;

const axiosInstance = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

export const getMyComingOrderLines = async (token) => {
  try {
    const response = await axiosInstance.get(`/order-lines/`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching the account:", error);
    throw error;
  }
};

export const getOrderLinesForAGivenDate = async (token, date) => {
  try {
    const startDate = new Date(date);
    const year = startDate.getFullYear();
    const month = String(startDate.getMonth() + 1).padStart(2, '0');
    const day = String(startDate.getDate()).padStart(2, '0');
    const response = await axiosInstance.get(`/order-lines/?startDate=${year}-${month}-${day}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching the account:", error);
    throw error;
  }
};