import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { getAggregateCurrentMonthPaidOrdersAmount } from "../../api/dashboardsAPI";

export const CurrentMonthPaidOrdersAmount = (props) => {
  const { getAccessTokenSilently } = useAuth0();
  const [sum, setSum] = useState();

  // Create our number formatter.
  const formatter = new Intl.NumberFormat("fr", {
    style: "currency",
    currency: "EUR",
    maximumSignificantDigits: 10,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = await getAccessTokenSilently();
        const accountId =
          props && props.account ? props.account._id : undefined;
        const fetchedData = await getAggregateCurrentMonthPaidOrdersAmount(
          token,
          accountId
        );
        setSum(fetchedData.result);
      } catch (e) {
        console.error(e);
      }
    };
    fetchData();
  }, []);

  return (
    <ul className="slds-has-dividers_around-space metrics">
      {sum !== undefined && (
        <li className="slds-item">
          <article className="slds-tile slds-tile_board">
            <h3
              className="slds-tile__title slds-truncate"
              title="Chiffre d'Affaires du Mois"
            >
              Chiffre d'Affaires du Mois
            </h3>
            <div className="slds-tile__detail">
              <p className="slds-text-heading_large">{formatter.format(sum)}</p>
              <p
                className="slds-truncate slds-has-alert"
                title="Montant total des commandes collectées ce mois"
              >
                Montant total des commandes collectées ce mois
              </p>
              <span
                className="slds-icon_container slds-tile_board__icon"
                title="description of icon when needed"
              >
                <svg
                  className="slds-icon slds-icon-text-default slds-icon_x-small"
                  aria-hidden="true"
                >
                  <use xlinkHref="/icons/utility-sprite/svg/symbols.svg#info_alt"></use>
                </svg>
                <span className="slds-assistive-text">Warning Icon</span>
              </span>
            </div>
          </article>
        </li>
      )}
    </ul>
  );
};

export default CurrentMonthPaidOrdersAmount;
