import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

import { getAccountRelatedOrders } from "../../api/accountsAPI";

import CreateModal from "../order/CreateModal.js";
import EmptyState from "../shared/EmptyState";

import { formatDate } from "../../utils/dateUtils";
import { getOrderDetailURL } from "../../utils/navigationUtils";
import { getLabel } from "../../utils/dataModelUtils";

export const RelatedOrders = (props) => {
  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();

  const [orders, setOrders] = useState([]);
  const [isCreateVisible, setIsCreateVisible] = useState(false);

  const fetchData = async () => {
    try {
      const token = await getAccessTokenSilently();
      const response = await getAccountRelatedOrders(props.account._id, token);
      if (response !== null) {
        setOrders(response);
      }
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const openRecord = (recordId) => {
    navigate(getOrderDetailURL(recordId));
  };

  const showCreateForm = () => {
    setIsCreateVisible(true);
  };

  const hideCreateForm = () => {
    setIsCreateVisible(false);
  };

  return (
    <article className="slds-card">
      <div className="slds-card__header slds-grid">
        <header className="slds-media slds-media_center slds-has-flexi-truncate">
          <div className="slds-media__body">
            <h2 className="slds-card__header-title">
              <span className="slds-truncate">Commandes ({orders.length})</span>
            </h2>
          </div>
          <div className="slds-no-flex">
            <button
              className="slds-button slds-button_neutral"
              onClick={showCreateForm}
            >
              <svg
                className="slds-button__icon slds-button__icon_left"
                aria-hidden="true"
              >
                <use xlinkHref="/icons/utility-sprite/svg/symbols.svg#new"></use>
              </svg>
              Nouvelle
            </button>
          </div>
        </header>
      </div>
      <div className="slds-card__body">
        {orders.length === 0 && (
          <EmptyState
            heading={"Ajoutez une commande"}
            body={'En cliquant sur "Nouvelle"'}
          />
        )}
        {orders.length > 0 && (
          <table
            className="slds-table slds-no-row-hover slds-table_bordered slds-table_fixed-layout slds-max-medium-table_stacked"
            role="grid"
          >
            <thead>
              <tr className="slds-line-height_reset">
                <th className="slds-cell-wrap" scope="col">
                  <div title="Reference">Référence</div>
                </th>
                <th className="slds-cell-wrap" scope="col">
                  <div title="Date">Date d'émission</div>
                </th>
                <th className="slds-cell-wrap" scope="col">
                  <div title="Montant TTC">Montant TTC</div>
                </th>
                <th className="slds-cell-wrap slds-show_medium" scope="col">
                  <div title="Statut">Statut</div>
                </th>
              </tr>
            </thead>
            <tbody>
              {orders.map((order) => (
                <tr className="slds-hint-parent datatable-row" key={order._id}>
                  <th data-label="Référence" scope="row" className="slds-cell-wrap">
                    <div className="slds-truncate datatable-value" title={order.orderReference}>
                      <a
                        href="#view"
                        tabIndex="-1"
                        onClick={() => openRecord(order._id)}
                      >
                        {order.orderReference}
                      </a>
                    </div>
                  </th>
                  <td
                    data-label="Date"
                    className="slds-cell-wrap"
                  >
                    <div className="slds-truncate datatable-value" title={formatDate(order.orderDate)}>
                      {formatDate(order.orderDate)}
                    </div>
                  </td>
                  <td data-label="Montant TTC" className="slds-cell-wrap">
                    <div className="slds-truncate datatable-value" title={order.totalAmountWithTax}>
                      {order.totalAmountWithTax}€
                    </div>
                  </td>
                  <td data-label="Statut" className="slds-cell-wrap">
                    <div className="slds-truncate datatable-value" title={order.orderStatus}>
                      {getLabel("orderStatus", order.orderStatus)}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
      {isCreateVisible && (
        <CreateModal onClose={hideCreateForm} account={props.account} />
      )}
    </article>
  );
};

export default RelatedOrders;
