import React from "react";
import {
  formatFromAddressOrderLine,
  formatToAddressOrderLine,
} from "../../utils/stringUtils";

export const Maps = (props) => {

  const openGoogleMaps = () => {
    let url;
    if (props.type && props.type === "from") {
      url = `https://www.google.com/maps/search/?api=1&query=${formatFromAddressOrderLine(
        props.orderline
      )}`;
    } else if (props.type && props.type === "to") {
      url = `https://www.google.com/maps/search/?api=1&query=${formatToAddressOrderLine(
        props.orderline
      )}`;
    } else if (props.type && props.type === "direction") {
      url = `https://www.google.com/maps/dir/${formatFromAddressOrderLine(
        props.orderline
      )}/${formatToAddressOrderLine(props.orderline)}`;
    }
    window.open(url);
  };

  const openWaze = () => {
    let url;
    if (props.type && props.type === "from") {
      url = `https://www.waze.com/ul?q=${formatFromAddressOrderLine(
        props.orderline
      )}`;
    } else if (props.type && props.type === "to") {
      url = `https://www.waze.com/ul?q=${formatToAddressOrderLine(
        props.orderline
      )}`;
    } else if (props.type && props.type === "direction") {
      url = `https://waze.com/fr/live-map/directions?ll=${props.orderline.toAddressLat},${props.orderline.toAddressLng}&from=${props.orderline.fromAddressLat},${props.orderline.fromAddressLng}`;
    }
    window.open(url);
  };

  return (
    <div>
      <section
        role="dialog"
        tabIndex="-1"
        aria-modal="true"
        aria-labelledby="modal-heading-01"
        className="slds-modal slds-fade-in-open slds-modal_small"
      >
        <div className="slds-modal__container">
          <button
            className="slds-button slds-button_icon slds-modal__close slds-button_icon-inverse"
            onClick={() => props.close && props.close()}
          >
            <svg
              className="slds-button__icon slds-button__icon_large"
              aria-hidden="true"
            >
              <use xlinkHref="/icons/utility-sprite/svg/symbols.svg#close"></use>
            </svg>
            <span className="slds-assistive-text">Cancel and close</span>
          </button>
          <div className="slds-modal__header">
            <h1
              id="modal-heading-01"
              className="slds-modal__title slds-hyphenate"
            >
              Votre itinéraire
            </h1>
          </div>
          <div
            className="slds-modal__content slds-p-around_medium"
            id="modal-content-id-1"
          >
            <a
              href="#google"
              className="slds-box slds-box_link slds-box_x-small slds-media"
              onClick={() => {
                openGoogleMaps();
                props.close && props.close();
              }}
            >
              <div className="slds-media__figure slds-media__figure_fixed-width slds-align_absolute-center slds-m-left_xx-small">
                <span className="slds-icon_container slds-icon-utility-knowledge_base">
                  <img
                    height="30"
                    width="30"
                    src="/images/Google_Maps_icon.svg"
                    alt="Google Maps"
                  />
                </span>
              </div>
              <div className="slds-media__body slds-border_left slds-p-around_small">
                <h2
                  className="slds-truncate slds-text-heading_small"
                  title="Google Maps"
                >
                  Google Maps
                </h2>
                <p className="slds-m-top_small">
                  Navigation et informations sur les commerces.
                </p>
              </div>
            </a>
            <br />
            <a
              href="#waze"
              className="slds-box slds-box_link slds-box_x-small slds-media"
              onClick={() => {
                openWaze();
                props.close && props.close();
              }}
            >
              <div className="slds-media__figure slds-media__figure_fixed-width slds-align_absolute-center slds-m-left_xx-small">
                <span className="slds-icon_container slds-icon-utility-knowledge_base">
                  <img
                    height="30"
                    width="30"
                    src="/images/Waze_icon.svg"
                    alt="Google Maps"
                  />
                </span>
              </div>
              <div className="slds-media__body slds-border_left slds-p-around_small">
                <h2
                  className="slds-truncate slds-text-heading_small"
                  title="Waze"
                >
                  Waze
                </h2>
                <p className="slds-m-top_small">
                  Navigation communautaire en temps réel.
                </p>
              </div>
            </a>
          </div>
          <div className="slds-modal__footer">
            <button
              className="slds-button slds-button_neutral"
              aria-label="Cancel and close"
              onClick={() => props.close && props.close()}
            >
              Annuler
            </button>
          </div>
        </div>
      </section>
      <div
        className="slds-backdrop slds-backdrop_open"
        role="presentation"
      ></div>
    </div>
  );
};

export default Maps;
