import React from "react";

export const ArticleLine = (props) => {
  return (
    <li className="slds-setup-assistant__item">
      <article className="slds-setup-assistant__step">
        <div className="slds-setup-assistant__step-summary">
          <div className="slds-media">
            <div className="slds-setup-assistant__step-summary-content slds-media__body">
              <h3 className="slds-setup-assistant__step-summary-title slds-text-heading_small">
                {props.title}
              </h3>
              <p dangerouslySetInnerHTML={{ __html: props.body }} />
            </div>
          </div>
        </div>
      </article>
    </li>
  );
};

export default ArticleLine;
