import axios from "axios";

const BASE_URL = process.env.REACT_APP_AWS_API_GATEWAY_URL;

const axiosInstance = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

export const getQuote = async (token, orderId) => {
  try {
    const response = await axiosInstance.post(
      `/stripe/quotes/`,
      { orderId: orderId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching the account:", error);
    throw error;
  }
};
