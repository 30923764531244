import React from "react";

export const Loader = (props) => {
  return (
    <section
      role="dialog"
      tabIndex="-1"
      aria-modal="true"
      aria-labelledby="modal-heading-01"
      style={{ backgroundColor: "#e5e5e5", opacity: 0.7, zIndex: 999999 }}
      className="slds-modal slds-fade-in-open slds-modal_full"
    >
      <div
        role="status"
        className="slds-spinner slds-spinner_large slds-spinner_brand"
      >
        <span className="slds-assistive-text">Chargement</span>
        <div className="slds-spinner__dot-a"></div>
        <div className="slds-spinner__dot-b"></div>
      </div>
      <p className="spinner-text">Chargement en cours</p>
    </section>
  );
};

export default Loader;
