import React from "react";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Loading from "./components/Loading";
import HorizontalHeaderContainer from "./components/HorizontalHeaderContainer";
import VerticalHeaderContainer from "./components/VerticalHeaderContainer";

import Home from "./views/Home";
import Search from "./views/Search";
import Support from "./views/Support";

import Accounts from "./views/Accounts";
import AccountDetail from "./views/AccountDetail";

import Orders from "./views/Orders";
import OrderDetail from "./views/OrderDetail";

import Dashboards from "./views/Dashboards";
import Agenda from "./views/Agenda";

// styles
import "./App.css";

const App = () => {
  const { isLoading, error } = useAuth0();

  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Router>
      <div id="app" className="d-flex flex-column slds-theme">
        <HorizontalHeaderContainer />
        <VerticalHeaderContainer />
        <div className="slds-brand-band slds-brand-band_large slds-p-top_small slds-p-left_x-small slds-p-right_x-small">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/accounts" element={<Accounts />} />
            <Route path="/accounts/:id/view" element={<AccountDetail />} />
            <Route path="/orders" element={<Orders />} />
            <Route path="/orders/:id/view" element={<OrderDetail />} />
            <Route path="/search" element={<Search />} />
            <Route path="/support" element={<Support />} />
            <Route path="/dashboards" element={<Dashboards />} />
            <Route path="/agenda" element={<Agenda />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
};

export default withAuthenticationRequired(App);