import React from "react";

import ArticleLine from "./ArticleLine";

export const Articles = () => {
  const articles = [
    {
      title: "Créer un client",
      body: "Avant de créer votre commande, assurez-vous d'avoir créé votre client d'abord. Pour cela, se référer à la section <i>Comment créer un client ?</i>.",
    },
    {
      title: "Créer une commande",
      body: "Avant de créer votre commande, assurez-vous d'avoir créé votre client d'abord. Pour cela, se référer à la section <i>Comment créer un client ?</i>.",
    },
    {
      title: "Créer une course",
      body: "Avant de créer votre commande, assurez-vous d'avoir créé votre client d'abord. Pour cela, se référer à la section <i>Comment créer un client ?</i>.",
    },
  ];

  return (
    <ol className="slds-setup-assistant" style={{ backgroundColor: "#ffffff" }}>
      {articles.map((article) => (
        <ArticleLine title={article.title} body={article.body}/>
      ))}
    </ol>
  );
};

export default Articles;